import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cliente } from '../_model/cliente';
import { AbstactService } from './abstact-service';
import { Observable } from 'rxjs';
import { Settings } from '../repository/settings/settings';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends AbstactService<Cliente> {

  constructor(http: HttpClient) {
    super(http, "clienteWS");
  }

  update(usuario: Cliente): Observable<Cliente> {
    return this.http.put<Cliente>(Settings.ENDPOINT + '/'
      + this.webservice + '/actualizarCliente', usuario, { headers: Settings.HEADERS }).catch(
        this.handleError
      );
  }
}
