import { Filter } from './Filter';

export class DashboardFiltros {

  idComprador?: number;
  tipoGrafico?: string;
  anio?: number;
  mes?: number;

}
