export const facteConstantes = {
    ERROR: 'Existio error al procesar su solicitud',
    SATISFACTORIO: 'Solictuda realizada satisfactoriamente',
    AJUSTE_FECHA: 'T00:00:00',
    SORT_ORDER_ASC: 1,
    SORT_ORDER_DESC: 0,
    FILTRO: 'filtro',
    PAGE_SIZE: '_pageSize',
    NUMBER_PAGE_SIZE: 1000,
    MAX_NUMBER_PAGE_SIZE: '1000',
    FILAS_POR_PAGINA: [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
    SELECCIONE_REGISTRO: 'Seleccione un registro',
    ERROR_REGISTRO: 'Error al guardar el registro',
    ERROR_SERVER: 'A ocurrido un error en el servidor',
    notificaciones: {
        warning: {
            DETAIL: 'No se encuentran datos con los filtros seleccionados'
        },
        error: {
            DETAIL: 'Error al buscar datelles con los filtros seleccionados',
            PEDIDO_SIN_CAJAS: 'No existen variedades en la caja ',
            CAJA_SIN_DET_COMPLETOS: 'Información de variedad faltante'
        },
        success: {
            DETAIL: 'Datos guardados con éxito'
        },
    },
    dashboard: {
        notificaciones: {
            success: {
            },
            error: {
            }
        }
    },

    documento: {
        columnas: [
            { field: 'fechaEmision', header: 'Fecha Emision' },
            { field: 'numeroDocumento', header: 'Numero Documento' },
            { field: 'identificacion', header: 'Identificación' },
            { field: 'razonSocial', header: 'Razón Social' },
            { field: 'claveAcceso', header: 'Clave Acceso' }
        ]
    },

    cliente: {
        columnas: [
            { field: 'userName', header: 'Nombre Usuario' },
            { field: 'identificacion', header: 'Identificación' },
            { field: 'razonSocial', header: 'Razon Social' },
            { field: 'direccion', header: 'Dirección' },
            { field: 'emails', header: 'Correo' }
        ]
    }
};
