import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Documento } from '../_model/Documento';
import { Cliente } from '../_model/cliente';

import { saveAs as importedSaveAs } from "file-saver";

import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/primeng';
import { DatePipe } from '@angular/common';
import { DocumentoService } from '../_services/documento.service';
import { CargarFiltrosUtil } from '../_util/cargar-filtros-util';
import { TipoDocumento } from '../_model/enum/tipo-documento';
import { DocumentoInteface } from '../_model/interfaces/documento-inteface';
import { facteConstantes } from 'src/app/_util/facte.constants';

@Component({
    selector: 'app-comprobantes',
    providers: [DatePipe, DocumentoService],
    templateUrl: './comprobantes.component.html'
})
export class ComprobantesComponent implements OnInit {
    public form: FormGroup;

    colsFacturas: any[];
    colsCreditos: any[];
    colsRetenciones: any[];
    colsLiquidaciones: any[];

    colsCliente: any[];
    user: Cliente;
    documento: Documento;
    identificacionFilter: string;
    displayDialog: boolean;

    documentoSeleccionado: Documento;

    facturas: Documento[];
    datasource: Documento[];
    Ifacturas: DocumentoInteface[] = [];
    creditos: Documento[];
    Icreditos: DocumentoInteface[] = [];
    retenciones: Documento[];
    Iretenciones: DocumentoInteface[] = [];
    liquidaciones: Documento[];
    Iliquidaciones: DocumentoInteface[] = [];
    users: Cliente[] = [];
    timeout: any;
    displayDialogPerfiles: boolean;
    displayDialogPerfil: boolean;
    user1: Cliente;
    seleccionarDocumento: Documento;
    loading: boolean;
    totalFacturas: number;
    totalCreditos: number;
    totalRetenciones: number;
    totalLiquidaciones: number;
    constantes: any;

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private documentoService: DocumentoService

    ) {
        this.constantes = facteConstantes.documento
    }

    ngOnInit() {
        //Recupero Usuario Session
        this.user = <Cliente>JSON.parse(localStorage.getItem('currentUser')).user;
        this.colsFacturas = this.constantes.columnas;
        this.colsCreditos = this.constantes.columnas;
        this.colsRetenciones = this.constantes.columnas;
        this.colsLiquidaciones = this.constantes.columnas;
        this.loading = true;

    }

    cargarFiltros(event: LazyLoadEvent) {
        const mapFiltros: Map<string, any> = CargarFiltrosUtil.getDatosFiltro(event);
        mapFiltros.forEach((value: any, key: string) => {
            console.log(key, value);
            console.log(key)
            switch (key) {
                case 'identificacion':
                    mapFiltros.set('comprador.identificacion', value);
                    mapFiltros.delete(key);
                    break;
            }
        });
        console.log(mapFiltros)
        mapFiltros.set('comprador.idComprador', this.user.idComprador);
        mapFiltros.set('comprador.indicadorEmisor', this.user.indicadorEmisor);
        return mapFiltros;
    }

    //table Docs
    tablaFacturas(event: LazyLoadEvent) {
        this.loading = true;
        this.Ifacturas = [];
        // setTimeout(() => {
        var mapFiltros: Map<string, any> = this.cargarFiltros(event);
        mapFiltros.set('tipoDocumento', TipoDocumento.FACTURA_CLIENTE);
        this.documentoService.getListaPagina(event.first, event.rows, event.sortField, event.sortOrder, mapFiltros).
            subscribe((data: Documento[]) => {
                this.datasource = data;
                let secuencial = 1;
                data.forEach(documento => {
                    const documentoInteface = this.crearInterfazDocumento(documento, secuencial);
                    this.Ifacturas.push(documentoInteface)
                    secuencial = secuencial + 1;
                });
                this.loading = false;
            });

        this.documentoService.getNumeroFilas(mapFiltros).subscribe((total: number) => {
            this.totalFacturas = total;
        });

        // }, 1000);

        setTimeout(() => {
            if (this.datasource) {
                this.facturas = this.datasource.slice(event.first, (event.first + event.rows));
                this.loading = false;
            }
        }, 1000);
    }

    tablaCreditos(event: LazyLoadEvent) {

        this.loading = true;
        this.Icreditos = [];
        setTimeout(() => {
            var mapFiltros: Map<string, any> = this.cargarFiltros(event);
            mapFiltros.set('tipoDocumento', TipoDocumento.NOTA_CREDITO);
            this.documentoService.getListaPagina(event.first, event.rows, event.sortField, event.sortOrder, mapFiltros)
                .subscribe((data: Documento[]) => {
                    this.creditos = data;
                    let secuencial = 1;
                    data.forEach(documento => {
                        const documentoInteface = this.crearInterfazDocumento(documento, secuencial);
                        this.Icreditos.push(documentoInteface)
                        secuencial = secuencial + 1;
                    });
                    this.loading = false;
                });

            this.documentoService.getNumeroFilas(mapFiltros).subscribe((total: number) => {
                this.totalCreditos = total;
            });

        }, 100);
    }

    tablaRetenciones(event: LazyLoadEvent) {

        this.loading = true;
        this.Iretenciones = [];
        setTimeout(() => {
            var mapFiltros: Map<string, any> = this.cargarFiltros(event);
            mapFiltros.set('tipoDocumento', TipoDocumento.RETENCION_PROVEEDOR);
            this.documentoService.getListaPagina(event.first, event.rows, event.sortField, event.sortOrder, mapFiltros)
                .subscribe((data: Documento[]) => {
                    this.retenciones = data;
                    let secuencial = 1;
                    data.forEach(documento => {
                        const documentoInteface = this.crearInterfazDocumento(documento, secuencial);
                        this.Iretenciones.push(documentoInteface)
                        secuencial = secuencial + 1;
                    });
                    this.loading = false;
                });

            this.documentoService.getNumeroFilas(mapFiltros).subscribe((total: number) => {
                this.totalRetenciones = total;
            });

        }, 100);
    }

    tablaLiquidaciones(event: LazyLoadEvent) {

        this.loading = true;
        this.Iliquidaciones = [];
        setTimeout(() => {
            var mapFiltros: Map<string, any> = this.cargarFiltros(event);
            mapFiltros.set('tipoDocumento', TipoDocumento.LIQUIDACION_COMPRA);

            this.documentoService.getListaPagina(event.first, event.rows, event.sortField, event.sortOrder, mapFiltros)
                .subscribe((data: Documento[]) => {
                    this.liquidaciones = data;
                    let secuencial = 1;
                    data.forEach(documento => {
                        const documentoInteface = this.crearInterfazDocumento(documento, secuencial);
                        this.Iliquidaciones.push(documentoInteface)
                        secuencial = secuencial + 1;
                    });
                    this.loading = false;
                });

            this.documentoService.getNumeroFilas(mapFiltros).subscribe((total: number) => {
                this.totalLiquidaciones = total;
            });
        }, 100);
    }

    crearInterfazDocumento(documento: Documento, secuencial: number): DocumentoInteface {
        const doc: DocumentoInteface = {
            secuencial: (secuencial),
            idDocumento: documento.idDocumento,
            identificacion: documento.comprador.identificacion,
            razonSocial: documento.razonSocial,
            numeroDocumento: documento.numeroDocumento,
            fechaEmision: documento.fechaEmision,
            fechaAutorizacion: documento.fechaAutorizacion,
            claveAcceso: documento.claveAcceso,
            numeroComprobanteRetenido: documento.numeroComprobanteRetenido,
            totalSinImpuestos: documento.totalSinImpuestos,
            totalDescuento: documento.totalDescuento,
            importeTotal: documento.importeTotal,
            totalRetenido: documento.totalRetenido,
            comprador: documento.comprador
        }
        return doc;
    }

    isDisabled() {
        return this.user.indicadorAdministrador;
    }

    onRowSelectFactura(event) {
        this.documentoSeleccionado = this.cloneDocumento(event.data);
        this.displayDialog = true;
    }

    cloneDocumento(d: Documento): Documento {
        const doc = Documento;
        for (let prop in d) {
            doc[prop] = d[prop];
        }
        return d;
    }


    downloadFileXml(claveAcceso, idDocumento) {
        this.documentoService.documentDownload(idDocumento, 'xml').subscribe((data: Documento) => {
            const byteCharacters = atob(data.xlmPdf);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type: 'txt/xml'
            });
            importedSaveAs(blob, claveAcceso + '.xml');
        });
    }

    openFilePdf(claveAcceso, idDocumento) {
        this.documentoService.documentDownload(idDocumento, 'pdf').subscribe((data: Documento) => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // To support IE and Edge
                const byteCharacters = atob(data.xlmPdf);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: 'application/pdf'
                });

                importedSaveAs(blob, claveAcceso + '.pdf');
                //window.navigator.msSaveOrOpenBlob(blob, claveAcceso + '.pdf');
            } else {
                // Other browsers
                const fileURL = `data:${'application/pdf'};base64,${data.xlmPdf}`;
                const win = window.open();
                win.document.write(
                    '<iframe src="' +
                    fileURL +
                    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
                );
            }
        });
    }

}
