import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-alert',
    providers: [MessageService],
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService, private service: MessageService) { }

    ngOnInit() {
        this.subscription = this.alertService.getAlert()
            .subscribe(message => {
                switch (message && message.type) {
                    case 'success':
                        this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: message.text });
                        break;
                    case 'error':
                        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: message.text });
                        break;
                }
                this.message = message;
                console.log(message);

            });
    }

  //  ngOnDestroy() {
  //    this.subscription.unsubscribe();
  //  }

}
