import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

import { CommonModule, LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//import { routing, appRoutingProviders } from './app.routes';
import { AppRoutingModule } from './app.routes';

import { SecurityComponent } from './security-access/security/security.component';
import { AppComponent } from './app.component';

import { ButtonModule } from 'primeng/button';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { PanelModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import { GrowlModule } from 'primeng/primeng';
// used to create fake backend
import { fakeBackendProvider } from './_helpers/fake-backend';
//Services
import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { HomeModule } from '../app/_view/home.module';
import { ToastModule } from 'primeng/toast';
import { AlertComponent } from './repository/components/alert.component';


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    PanelModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    RouterModule.forRoot([]),
    HomeModule,
    AppRoutingModule,

  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent
  ],
  providers: [
    //  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    //  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //   fakeBackendProvider
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
