import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './_view/home.component';
import { ComprobantesComponent } from './_view/comprobantes.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DashboardComponent } from './_view/dashboard.component';
import { ClienteComponent } from './_view/cliente.component';
export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }


