import { Injectable } from '@angular/core';
import { Documento } from '../_model/Documento';
import { AbstactService } from './abstact-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Settings } from '../repository/settings/settings';
import { DashboardFiltros } from '../_model/dashboard-filtros';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService extends AbstactService<Documento> {

  constructor(http: HttpClient) {
    super(http, "dashboardWS");
  }

  cantidadDocumentos(
    idComprador: number,
    tipoGrafico: string,
    anio: number,
    mes: number):
    Observable<Documento[]> {
    const filtroDashboard = new DashboardFiltros();
    filtroDashboard.idComprador = idComprador;
    filtroDashboard.tipoGrafico = tipoGrafico;
    filtroDashboard.anio = anio;
    filtroDashboard.mes = mes;
    return this.http.put<Documento[]>(Settings.ENDPOINT + '/' + this.webservice + '/cantidadComprobantes'
      , filtroDashboard,
      { headers: Settings.HEADERS, responseType: "json" }).catch(
        this.handleError
      );
  }

  documentDownload(idDocumento: number, tipo: string): Observable<Documento> {
    console.log(Settings.HEADERS);
    return this.http.put<Documento>(Settings.ENDPOINT + '/' + this.webservice + '/comprobante/' + idDocumento + '/' + tipo,
      { headers: Settings.HEADERS, responseType: "json" }).catch(
        this.handleError
      );
  }
}
