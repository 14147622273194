import { Component, OnInit } from '@angular/core';
import { Cliente } from '../_model/cliente';
import { ClienteService } from '../_services/cliente.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { CargarFiltrosUtil } from '../_util/cargar-filtros-util';
import { facteConstantes } from 'src/app/_util/facte.constants';
import { AlertService } from '../_services/alert.service';


@Component({
  selector: 'app-cliente',
  providers: [ClienteService],
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {

  user: Cliente;
  clientes: Cliente[];
  colsCliente: any[];
  loading: boolean;
  totalCliente: number;
  seleccionarCliente: Cliente;
  displayDialogCliente: boolean;
  user1: Cliente;
  constantes: any;

  constructor(
    private clienteService: ClienteService,
    private messageService: MessageService,
    private alertService: AlertService
  ) {
    this.constantes = facteConstantes.cliente
  }

  ngOnInit() {
    this.user = <Cliente>JSON.parse(localStorage.getItem('currentUser')).user;
    this.colsCliente = this.constantes.columnas;
    this.loading = true;
  }

  cargarFiltros(event: LazyLoadEvent) {
    const mapFiltros: Map<string, any> = CargarFiltrosUtil.getDatosFiltro(event);
    mapFiltros.forEach((value: any, key: string) => {
      console.log(key, value);
      console.log(key)
      switch (key) {
        case 'identificacion':
          mapFiltros.set('comprador.identificacion', value);
          mapFiltros.delete(key);
          break;
        case 'razonSocial':
          mapFiltros.set('comprador.razonSocial', value);
          mapFiltros.delete(key);
          break;
        case 'direccion':
          mapFiltros.set('comprador.direccion', value);
          mapFiltros.delete(key);
          break;
        case 'emails':
          mapFiltros.set('comprador.emails', value);
          mapFiltros.delete(key);
          break;

        default:

          break;
      }
    });
    console.log(mapFiltros)
    mapFiltros.set('comprador.idComprador', this.user.idComprador);
    mapFiltros.set('comprador.indicadorEmisor', this.user.indicadorEmisor);
    return mapFiltros;
  }

  tablaClientes(event: LazyLoadEvent) {
    this.loading = true;

    setTimeout(() => {
      var mapFiltros: Map<string, any> = this.cargarFiltros(event);
      this.clienteService.getListaPagina(event.first, event.rows, 'userName', event.sortOrder, mapFiltros).
        subscribe((data: Cliente[]) => {
          this.clientes = data;
          this.loading = false;
        });

      this.clienteService.getNumeroFilas(mapFiltros).subscribe((total: number) => {
        this.totalCliente = total;
      });

    }, 100);
  }

  onRowSelectCliente(event) {
    this.user1 = this.cloneCliente(event.data);
    this.displayDialogCliente = true;
  }

  cloneCliente(u: Cliente): Cliente {
    let user = {};
    for (let prop in u) {
      user[prop] = u[prop];
    }
    return u;
  }

  update(): void {
    this.clienteService.update(this.user1)
      .subscribe(user => {
        this.user1 = null;
        this.displayDialogCliente = false;
        this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Datos guardados con éxito' });
      },
          error => {
            this.alertService.error(error);
          });
  }
}
