import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../_view/home.component';
import { DashboardComponent } from '../_view/dashboard.component';
import { ComprobantesComponent } from '../_view/comprobantes.component';
import { AuthGuard } from '../_helpers/auth.guard';
import { HomeModule } from '../_view/home.module';
import { ClienteComponent } from './cliente.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      { path: 'comprobantes', component: ComprobantesComponent },
      { path: 'cliente', component: ClienteComponent }

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
