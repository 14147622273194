import { Component, AfterViewInit, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ScrollPanel } from 'primeng/primeng';
import { AuthenticationService } from '../_services/authentication.service'
import { Router } from '@angular/router';
import { Cliente } from '../_model/Cliente';
import { DashboardService } from '../_services/dashboard.service'
import { MessageService } from 'primeng/api';
import { AlertComponent } from '../repository/components/alert.component'
import { AlertService } from '../_services/alert.service';
import { ClienteService } from '../_services/cliente.service';

@Component({
    selector: 'app-home',
    providers: [MessageService],
    templateUrl: 'home.component.html',
    animations: [
        trigger('submenu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class HomeComponent implements AfterViewInit, OnDestroy {

    public menuInactiveDesktop: boolean;

    public menuActiveMobile: boolean;

    public profileActive: boolean;

    public topMenuActive: boolean;

    public topMenuLeaving: boolean;

    @ViewChild('scroller', { static: true }) public scrollerViewChild: ScrollPanel;

    documentClickListener: () => void;

    menuClick: boolean;

    topMenuButtonClick: boolean;

    user: Cliente;
    user1: Cliente;

    displayDialogPerfil: boolean;


    constructor(public renderer: Renderer2,
        private router: Router,
        private authenticationService: AuthenticationService,
        private messageService: MessageService,
        private alertService: AlertService,
        private clienteService: ClienteService
    ) {
        this.user = <Cliente>JSON.parse(localStorage.getItem('currentUser')).user;
    }

    ngAfterViewInit() {
        setTimeout(() => { this.scrollerViewChild.moveBar(); }, 100);
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.isDesktop()) {
                if (!this.menuClick) {
                    this.menuActiveMobile = false;
                }
                if (!this.topMenuButtonClick) {
                    this.hideTopMenu();
                }
            }

            this.menuClick = false;
            this.topMenuButtonClick = false;
        });
    }

    toggleMenu(event: Event) {
        this.menuClick = true;
        if (this.isDesktop()) {
            this.menuInactiveDesktop = !this.menuInactiveDesktop;
            if (this.menuInactiveDesktop) {
                this.menuActiveMobile = false;
            }
        } else {
            this.menuActiveMobile = !this.menuActiveMobile;
            if (this.menuActiveMobile) {
                this.menuInactiveDesktop = true;
            }
        }

        if (this.topMenuActive) {
            this.hideTopMenu();
        }

        event.preventDefault();
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
        this.editPerfil()
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = true;
        }

        event.preventDefault();
    }

    hideTopMenu() {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = false;
            this.topMenuLeaving = false;
        }, 500);
    }

    onMenuClick() {
        this.menuClick = true;

        setTimeout(() => { this.scrollerViewChild.moveBar(); }, 500);
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['login']);
    }

    editPerfil() {
        this.user1 = this.cloneUser(this.user);
        this.displayDialogPerfil = true;
    }

    cloneUser(u: Cliente): Cliente {
        let user = {};
        for (let prop in u) {
            user[prop] = u[prop];
        }
        return u;
    }

    update(): void {
        this.clienteService.update(this.user1)
            .subscribe(user => {
                this.user1 = null;
                this.displayDialogPerfil = false;
                this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Datos guardados con éxito' });
            },
                error => {
                    this.alertService.error(error);
                });
    }
}
