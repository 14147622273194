import { AbstractEntity } from './abstract-entity';
import { Comprador } from './comprador';


export class Documento extends AbstractEntity {
    idDocumento: number;
    identificacion: string;
    razonSocial: string;
    numeroDocumento: string;
    fechaEmision: Date;
    fechaAutorizacion: Date;
    claveAcceso: string;
    numeroComprobanteRetenido: string;
    totalSinImpuestos: number;
    totalDescuento: number;
    importeTotal: number;
    totalRetenido: number;
    cantidadDocumentos: number;
    tipoDocumento: any;
    mes: string;
    xlmPdf: any;
    comprador: Comprador;
}
