import { Injectable } from '@angular/core';
import { AbstractEntity } from '../_model/abstract-entity';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Settings } from '../repository/settings/settings';
import { Observable, throwError, pipe } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CargarFiltrosUtil } from '../_util/cargar-filtros-util';

@Injectable({
  providedIn: 'root'
})
export class AbstactService<T extends AbstractEntity> {

  http: HttpClient;
  webservice: string;

  msgs: Message[] = [];

  constructor(http: HttpClient, webservice: string) {
    this.http = http;
    this.webservice = webservice;
  }


  headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  getListaPagina(
    startIndex: number,
    pageSize: number,
    sortField: string,
    sortOrder: number,
    filters: Map<string, string>):
    Observable<T[]> {
    return this.http.post<T[]>(Settings.ENDPOINT + '/' +
      this.webservice + '/listaPagina', CargarFiltrosUtil.anadirFiltros(startIndex, pageSize, sortField, sortOrder, filters),
      { headers: this.headers, responseType: 'json' }).catch(
        this.handleError
      );
  }

  getNumeroFilas(filters: Map<string, string>): Observable<number> {
    return this.http.put<number>(Settings.ENDPOINT + '/' + this.webservice + '/contarPorCriterio',
      CargarFiltrosUtil.getConvertirFiltros(filters),
      { headers: Settings.HEADERS }).catch(
        this.handleError
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error al ejecutar la operación`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

}