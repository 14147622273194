import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers } from '@angular/http';
import { Cliente } from 'src/app/_model/Cliente';

export class Settings {

  public static get ENDPOINT(): string {
    // return 'http://127.0.0.1:8080'
    return 'http://201.218.49.48:8040/plataformafe-web-0.0.1-SNAPSHOT';
    //return 'http://190.95.162.110:8040/backplataform-web-2.2.4.RELEASE';
  }

  public static get HEADERS(): HttpHeaders {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', 'http://127.0.0.1:8020/*');
    headers.append('Access-Control-Allow-Methods', 'GET, PUT, POST, PATCH, DELETE, OPTIONS');
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');

    return headers;
  }

  public static get HEADERS_AUTHORIZATION(): Headers {
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', 'http://127.0.0.1:8080/*');
    headers.append('Access-Control-Allow-Methods', 'GET, PUT, POST, PATCH, DELETE, OPTIONS');
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentCliente')).token);
    return headers;
  }

}
