import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpModule } from '@angular/http';
import { first } from 'rxjs/operators';


import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { Cliente } from '../_model/Cliente';
import { MessageService } from 'primeng/api';

@Component({
    //selector: 'app-login',
    providers: [AuthenticationService, MessageService],
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public form: FormGroup;
    private user: Cliente;
    private data: any;
    returnUrl: string;
    error = '';
    loading = false;
    submitted = false;

    constructor(
        public formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private messageService: MessageService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
        this.initForm();
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }

    get f() { return this.form.controls; }

    login(form: any) {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        
        this.loading = true;
        this.authenticationService.login(this.f.userName.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Bienvenido' });
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;

                });
    }

    signup(event) {
        event.preventDefault();
        this.router.navigate(['signup']);
    }

    initForm() {
        this.form = this.formBuilder.group({
            userName: ['', [Validators.required,]],
            password: ['', [Validators.required,]]
        });
    }

}
