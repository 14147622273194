import { LazyLoadEvent, FilterMetadata } from 'primeng/api';
import { Filter } from '../_model/Filter';
import { LazyLoadParameters } from '../_model/lazy-load-parameters';

export class CargarFiltrosUtil {

	public static anadirFiltros(
		startIndex: number,
		pageSize: number,
		sortField: string,
		sortOrder: number,
		filters: any): LazyLoadParameters {
		const lazyLoadParameters = new LazyLoadParameters();
		lazyLoadParameters.startIndex = startIndex;
		lazyLoadParameters.pageSize = pageSize;
		lazyLoadParameters.sortField = sortField;
		lazyLoadParameters.sortOrder = sortOrder;
		lazyLoadParameters.filterList = this.getConvertirFiltrosLazy(filters).filterList;
		return lazyLoadParameters;
	}


	public static getDatosFiltro(event: LazyLoadEvent) {
		const filterList = new Map();
		for (const filterProperty in event.filters) {
			if (event.filters.hasOwnProperty(filterProperty)) {
				const filter = event.filters[filterProperty] as FilterMetadata;
				if (filter.matchMode && filter.matchMode !== '') {
					const odataProperty = filterProperty.replace(/\./g, '/');
					const odataValue = filter.value.toLowerCase().split(':');
					const dato = odataValue[0];
					filterList.set(odataProperty, dato);
				}
			}
		}
		return filterList;
	}

	private static getConvertirFiltrosLazy(filters: any): LazyLoadParameters {
		const lazyLoadParameters = new LazyLoadParameters();
		filters.forEach((value: any, key: string) => {
			const filter: Filter = new Filter();
			filter.key = key;
			filter.value = value;
			lazyLoadParameters.filterList.push(filter);
		});
		return lazyLoadParameters;
	}

	public static getConvertirFiltros(filters: any) {
		var filterList: Filter[] = [];
		filters.forEach((value: any, key: string) => {
			console.log(key)
			const filter: Filter = new Filter();
			filter.key = key;
			filter.value = value;
			filterList.push(filter);
		});
		return filterList;
	}
}