import { Filter } from './Filter';

export class LazyLoadParameters {

  startIndex?: number;
  pageSize?: number;
  sortOrder?: number;
  sortField?: string;
  filterList?: Filter[] = [];

}
