import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { retry, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError, pipe } from 'rxjs';

import { map } from 'rxjs/operators';
import 'rxjs/Rx'

import { Cliente } from '../_model/Cliente';
import { Settings } from '../repository/settings/settings';
import { RepositoryService } from '../_services/repository.service';
import { AbstactService } from './abstact-service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService extends AbstactService<Cliente> {

    private currentUserSubject: BehaviorSubject<Cliente>;
    public currentUser: Observable<Cliente>;

    constructor(http: HttpClient) {
        super(http, "usuarioWS");
        this.currentUserSubject = new BehaviorSubject<Cliente>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get currentUserValue(): Cliente {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        let body = new HttpParams({
            fromObject: {
                'userName': username,
                'password': password
            }
        })
        return this.http.post<Cliente>(Settings.ENDPOINT + '/'
            + this.webservice + '/authenticate', body,
            { headers: Settings.HEADERS, responseType: "json" })
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            })).catch(
                this.handleError
            );
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        //this.currentUserSubject.next(null);
    }

    cargarPermisos(usuario: Cliente): Observable<String[]> {
        return this.http.put<String[]>(Settings.ENDPOINT + '/' + this.webservice + '/permiso', usuario,
            { headers: Settings.HEADERS, responseType: "json" }).catch(
                this.handleError
            );
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Usuario o Password incorrectos`;
        }
        //window.alert(errorMessage);
        return throwError(errorMessage);
    }

}
