import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';

import 'rxjs/Rx';

import { Message } from 'primeng/primeng';

import { AbstractEntity } from '../_model/abstract-entity';
import { Settings } from '../repository/settings/settings';
import { Observable, throwError } from 'rxjs';

export class RepositoryService<T extends AbstractEntity>{

  public httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' })
  http: HttpClient;
  http1: Http;

  msgs: Message[] = [];

  constructor(http: HttpClient) {
    this.http = http;
  }

  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',

    // 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token,
    // 'Content-Type': 'application/json'
  });
}
