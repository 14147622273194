import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Documento } from '../_model/Documento';
import { Cliente } from '../_model/Cliente';
import { DashboardService } from '../_services/dashboard.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { DocumentoService } from '../_services/documento.service';
import { TipoDocumento } from '../_model/enum/tipo-documento';

@Component({
    selector: 'app-dashboard',
    providers: [DashboardService, DocumentoService, DatePipe],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public form: FormGroup;

    colsFacturas: any[];
    colsCreditos: any[];
    colsRetenciones: any[];
    colsLiquidaciones: any[];

    chartData: any;
    barData: any;
    pieData: any;
    usuario: Cliente;

    cantFacturas: Documento;
    cantCreditos: Documento;
    cantRetenciones: Documento;
    cantLiquidaciones: Documento;
    documento: Documento;

    month: Date;
    year: number

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        private dashboardService: DocumentoService,
    ) { }

    ngOnInit() {
        this.initForm();
        this.month = new Date();
        let month = this.month.getMonth() + 1;
        this.year = this.month.getFullYear();


        this.usuario = <Cliente>JSON.parse(localStorage.getItem('currentUser')).user;

        this.headerCantidadDocumentos(this.usuario, this.year);
        this.chartBarDashboard(this.usuario, this.year, month);
        this.chartDoughnutDashboard(this.usuario, this.year, month);
    }

    headerCantidadDocumentos(user: Cliente, year: number) {
        this.dashboardService.cantidadDocumentos(this.usuario.idComprador, '00', year, 0).subscribe((data: Documento[]) => {
            // tslint:disable-next-line: forin
            for (const i in data) {
                if (data[i].tipoDocumento == TipoDocumento.FACTURA_CLIENTE) {
                    this.cantFacturas = data[i]
                }
                if (data[i].tipoDocumento == TipoDocumento.NOTA_CREDITO) {
                    this.cantCreditos = data[i]
                }
                if (data[i].tipoDocumento == TipoDocumento.RETENCION_PROVEEDOR) {
                    this.cantRetenciones = data[i]
                }
                if (data[i].tipoDocumento == TipoDocumento.LIQUIDACION_COMPRA) {
                    this.cantLiquidaciones = data[i]
                }
            }
        });
    }

    chartBarDashboard(user: Cliente, month: number, anio: number) {
        //Total Docs
        const cantFacturas = [];
        const cantCreditos = [];
        const cantRetenciones = [];
        const cantLiquidaciones = [];
        var nameFactura: string = '';
        var nameCreditos: string = '';
        var nameRetenciones: string = '';
        var nameLiquidaciones: string = '';

        this.dashboardService.cantidadDocumentos(this.usuario.idComprador, '01', month, anio).subscribe((data: Documento[]) => {
            // tslint:disable-next-line: forin
            for (let i in data) {
                if (data[i].tipoDocumento == TipoDocumento.FACTURA_CLIENTE) {
                    cantFacturas.push(data[i].cantidadDocumentos)
                    nameFactura = data[i].tipoDocumento
                }

                if (data[i].tipoDocumento == TipoDocumento.NOTA_CREDITO) {
                    cantCreditos.push(data[i].cantidadDocumentos)
                    nameCreditos = data[i].tipoDocumento
                }
                if (data[i].tipoDocumento == TipoDocumento.RETENCION_PROVEEDOR) {
                    cantRetenciones.push(data[i].cantidadDocumentos)
                    nameRetenciones = data[i].tipoDocumento
                }
                if (data[i].tipoDocumento == TipoDocumento.LIQUIDACION_COMPRA) {
                    cantLiquidaciones.push(data[i].cantidadDocumentos)
                    nameLiquidaciones = data[i].tipoDocumento
                }



                this.barData = {
                    labels: ["enero", "febrero", "marzo", "abril", "mayo",
                        "junio", "julio", "agosto", "septiembre", "octubre", 'noviembre', 'diciembre'],
                    datasets: [
                        {
                            label: nameFactura,
                            backgroundColor: '#007be5',
                            borderColor: '#007be5',
                            data: cantFacturas
                        },
                        {
                            label: nameCreditos,
                            backgroundColor: '#ef6262',
                            borderColor: '#ef6262',
                            data: cantCreditos
                        },
                        {
                            label: nameRetenciones,
                            backgroundColor: '#20d077',
                            borderColor: '#20d077',
                            data: cantRetenciones
                        },
                        {
                            label: nameLiquidaciones,
                            backgroundColor: '#f9c851',
                            borderColor: '#f9c851',
                            data: cantLiquidaciones
                        }
                    ]
                };
            }
        });
    }

    chartDoughnutDashboard(user: Cliente, month: number, anio: number) {
        //Chart pieData
        const tipoDocumento = [];
        const cantidadDocumentos = [];
        this.dashboardService.cantidadDocumentos(this.usuario.idComprador, '02', month, anio).subscribe((data: Documento[]) => {
            // tslint:disable-next-line: forin
            for (let i in data) {
                tipoDocumento.push(data[i].tipoDocumento)
                cantidadDocumentos.push(data[i].cantidadDocumentos)
            }
            this.pieData = {
                labels: tipoDocumento,
                datasets: [
                    {
                        data: cantidadDocumentos,
                        backgroundColor: [
                            '#007be5',
                            '#ef6262',
                            '#20d077',
                            '#f9c851'
                        ],
                        hoverBackgroundColor: [
                            '#007be5',
                            '#ef6262',
                            '#20d077',
                            '#f9c851'
                        ]
                    }]
            };
        });
    }

    isDisabled() {
        return this.usuario.indicadorAdministrador;
    }

    get f() { return this.form.controls; }

    initForm() {
        this.form = this.formBuilder.group({
            mes: ['', []],
        });
    }

    updateChartsYear(event) {
        let month = this.month.getMonth() + 1;
        let year = this.year;
        this.headerCantidadDocumentos(this.usuario, year);
        this.chartBarDashboard(this.usuario, year, month);
        this.chartDoughnutDashboard(this.usuario, year, month);
    }

    updateChartsMonth(event) {
        let month = this.month.getMonth() + 1;
        let year = this.year;
        this.chartDoughnutDashboard(this.usuario, year, month);
    }

}
