import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, pipe } from 'rxjs';
import { Injectable } from "@angular/core";

import { Settings } from '../repository/settings/settings';
import { RepositoryService } from '../_services/repository.service';
import { Documento } from '../_model/Documento';
import { Cliente } from '../_model/cliente';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends RepositoryService<Documento>  {
    private currentDocumentSubject: BehaviorSubject<Documento>;
    public currentDocument: Observable<Documento>;
    constructor(http: HttpClient) {
        super(http);
    }

    public get currentClienteValue(): Documento {
        return this.currentDocumentSubject.value;
    }


    update(usuario: Cliente): Observable<Cliente> {
        return this.http.put<Cliente>(Settings.ENDPOINT + '/user', usuario, { headers: this.httpHeaders }).catch(
            this.handleError
        );
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error al ejecutar la operación`;
        }
        //window.alert(errorMessage);
        return throwError(errorMessage);
    }
}
